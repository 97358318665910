import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage: React.FC = () => (
    <Layout>
        <SEO title="404: Not found" />
        <section className="hero is-dark mt-6">
            <div className="hero-body">
                <div className="container">
                    <p className="title">CM Thunder Gold 18U Is Missing</p>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <p className="title">NOT FOUND</p>
                <p className="subtitle">You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </section>
    </Layout>
)

export default NotFoundPage
